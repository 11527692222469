<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12 text-center">
					<div class="card bg-primary border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Total de Ingresos</div>
								<div class="font-size-18">
									{{
										numeral(totals.online + totals.panel + totals.pacific.spareparts + totals.pacific.boutique + totals.rls - totalCanceledPacific - totalCanceledRLS - totalCanceledPanel).format(
											'$0,0.00',
										)
									}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-1"></div>
				<div class="col-md-2 text-center">
					<div class="card bg-purple border-0">
						<div class="card-body">
							<div class="font-weight-bold">
								<div class="font-size-21">Limpio</div>
								<div class="font-size-18">{{ numeral(totals.panel - totalCanceledPanel).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-2 text-center">
					<div class="card bgYellow border-0">
						<div class="card-body">
							<div class="font-weight-bold">
								<div class="font-size-21">VENTAS ON LINE</div>
								<div class="font-size-18">{{ numeral(totals.online).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-2 text-center">
					<div class="card bgGreen border-0">
						<div class="card-body">
							<div class="font-weight-bold">
								<div class="font-size-21 pointer" @click="openModal('pacific')">Boutique</div>
								<div class="font-size-18">{{ numeral(totals.pacific.boutique - totalCanceledPacific).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-2 text-center">
					<div class="card bg-success text-white border-0">
						<div class="card-body">
							<div class="font-weight-bold">
								<div class="font-size-21 pointer" @click="openModal('pacific')">Refacciones</div>
								<div class="font-size-18">{{ numeral(totals.pacific.spareparts - totalCanceledPacific).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-2 text-center">
					<div class="card bgRls border-0">
						<div class="card-body">
							<div class="font-weight-bold">
								<div class="font-size-21 pointer" @click="openModal('rls')">RLS</div>
								<div class="font-size-18">{{ numeral(totals.rls - totalCanceledRLS).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-1"></div>
			</div>
			<div class="row">
				<div class="col-md-3" v-if="isUserAdmin || user.user_id == 27 || user.user_id == 31">
					<b>Ubicación</b>
					<a-select :style="{ width: '100%' }" @change="initModule" v-model="filterBranchOffice">
						<a-select-option value="" selected> Todas </a-select-option>
						<a-select-option value="1"> Matriz </a-select-option>
						<a-select-option value="2"> Centro </a-select-option>
					</a-select>
				</div>
				<div class="col-md-3">
					<b>Tipo de filtro</b>
					<a-select :style="{ width: '100%' }" v-model="filterType">
						<a-select-option value="day"> Por día </a-select-option>
						<a-select-option value="range"> Rango de Fecha </a-select-option>
					</a-select>
				</div>
				<div class="col-md-3">
					<div v-if="filterType == 'range'">
						<b>Rango de Fecha</b>
						<!-- <a-icon type="reload" @click="onResetFilter('defaultDate')" :style="{ fontSize: '14px', color: 'red' }" /> -->
						<a-range-picker
							:inputReadOnly="true"
							style="width: 100%"
							@change="initModule"
							v-model="filters.defaultDate"
							:default-value="filters.defaultDate"
							:value-format="'YYYY-MM-DD'"
							:format="'YYYY-MM-DD'"
						/>
					</div>
					<div v-if="filterType == 'day'">
						<b>Por día</b>
						<a-date-picker
							:inputReadOnly="true"
							style="width: 100%"
							@change="initModule"
							v-model="filters.defaultDate[0]"
							:default-value="filters.defaultDate[0]"
							:value-format="'YYYY-MM-DD'"
							:format="'YYYY-MM-DD'"
						/>
					</div>
				</div>
				<!-- <div class="col-md-4">
					<b># de Ticket</b>
					<a-input v-model="filters.ticket" v-mask="'######'" v-on:keyup.enter="searchByTicket" allowClear @change="onResetTicket" />
				</div> -->
			</div>

			<a-table
				:columns="columns"
				:dataSource="tickets"
				class="pt20"
				:pagination="false"
				:rowClassName="
					(_record, index) => {
						if (_record.active < 1) {
							return 'bgRed'
						} else {
							if (_record.from_pacific) {
								return 'bgGreen'
							}
							if (_record.from_panel) {
								return 'bg-purple'
							}
							if (_record.from_rls) {
								return 'bgRls'
							}
						}
					}
				"
			>
				<div slot="id" slot-scope="record">
					{{ record.id }}
					<div v-if="record.service_order">
						<a-tag color="red"> ODS {{ record.service_order }} </a-tag>
					</div>
					<div>
						{{ record.fullName }}
					</div>
				</div>
				<div slot="total" slot-scope="record">
					{{ numeral(record.total).format('$0,0.00') }}
					<div>
						<span v-if="record.payment_cash > 0 && !record.cart">
							<a-tag color="green">
								{{ numeral(record.payment_cash).format('$0,0.00') }}
								<span style="font-size: 8px">
									<img src="resources/images/cash.png" height="20" />
									Efectivo
								</span>
							</a-tag>
							<br />
						</span>
						<span v-else-if="record.payment_cash > 0 && record.cart">
							<a-tag color="green">
								{{ numeral(record.payment_cash).format('$0,0.00') }}
								<span style="font-size: 8px">
									<img src="resources/images/cash.png" height="20" />
									Efectivo
								</span>
							</a-tag>
							<br />
						</span>
						<div v-if="record.payment_bank_terminal > 0">
							<a-tag color="blue">
								{{ numeral(record.payment_bank_terminal).format('$0,0.00') }}
								<span style="font-size: 8px">
									<img src="resources/images/credit-card.png" height="20" />
									Terminal
								</span>
							</a-tag>
							<br />
						</div>
						<span v-if="record.payment_transfer > 0">
							<a-tag color="orange">
								{{ numeral(record.payment_transfer).format('$0,0.00') }}
								<span style="font-size: 8px">
									<img src="resources/images/money-transfer.png" height="20" />
									Transferencias
								</span>
							</a-tag>
							<br />
						</span>
						<span v-if="record.payment_paypal > 0">
							<a-tag color="purple">
								{{ numeral(record.payment_paypal).format('$0,0.00') }}
								<span style="font-size: 8px">
									<img src="resources/images/paypal.png" height="20" />
									PayPal
								</span>
							</a-tag>
							<br />
						</span>
						<span v-if="record.payment_mercadopago > 0">
							<a-tag color="cyan">
								{{ numeral(record.payment_mercadopago).format('$0,0.00') }}
								<span style="font-size: 8px">
									<img src="resources/images/mercadopago.png" height="20" />
									MercadoPago
								</span>
							</a-tag>
							<br />
						</span>
						<span v-if="record.payment_aplazo > 0 && !record.cart">
							<a-tag color="gray" style="color: #000">
								{{ numeral(record.payment_aplazo).format('$0,0.00') }}
								<span style="font-size: 8px">
									<img src="resources/images/cash.png" height="20" />
									Aplazo
								</span>
							</a-tag>
							<br />
						</span>
					</div>
				</div>
				<div slot="cart" slot-scope="record">
					<div class="text-left">
						<div v-for="(element, index) in record.cart" :key="index">
							<div>
								{{ element.quantity }}x {{ element.product.sku }} - {{ element.product.label }} - {{ numeral(element.product.prices.sale_price).format('$0,0.00') }}
								<span v-if="element.quantity > 1">- ({{ numeral(element.quantity * element.product.prices.sale_price).format('$0,0.00') }})</span>
							</div>
						</div>
					</div>
				</div>

				<div slot="created_at" slot-scope="record">
					<div>{{ moment(record.created_at).tz('America/Mexico_City').format('DD-MM-YYYY') }}</div>
					<div>{{ moment(record.created_at).tz('America/Mexico_City').format('HH:mm:ss') }} hrs.</div>
				</div>
				<div slot="action" slot-scope="record">
					<a-button class="btn btn-success" size="small" icon="printer" @click="onPrintTicket(record)" v-if="record.from_panel || record.from_rls" />
					<div v-else>{{ record.pacific }}</div>
					<div v-if="record.active < 1">CANCELADO</div>
					<div v-if="record.salesman_info">
						<b>{{ record.salesman_info }}</b>
					</div>
					<!-- <a-button class="btn btn-warning ml7" size="small" icon="file-pdf" @click="onBillSale(record.id)" v-if="record.from == 'rls' && !record.billed" /> -->
				</div>
			</a-table>
		</a-spin>
		<ticketComponent
			v-if="ticketVisible"
			:ticketData="ticket.ticketData"
			:advances="ticket.advances"
			:theSalesman="ticket.fullName"
			:cart="ticket.cart"
			:paymentData="ticket.paymentData"
			:hasGiftTicket="giftTicket"
			:serviceOrder="ticket.serviceOrder"
			:orderDiscount="ticket.orderDiscount"
			:client="localClient"
		/>
		<a-modal :visible="modalDetailBoutiqueVisible" title="Detalle de ingresos" :closable="true" @cancel="onCloseModal('boutique')" width="70%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('boutique')"> Cerrar </a-button>
			</template>
			<div class="row">
				<div class="col-md-12 text-center">
					<div class="card bg-primary border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Total de Ingresos</div>
								<div class="font-size-18" v-if="modalType == 'pacific'">
									{{ numeral(totals.pacific.boutique + totals.pacific.spareparts - totalCanceledPacific).format('$0,0.00') }}
								</div>
								<div class="font-size-18" v-else>
									{{ numeral(totals[modalType] - totalCanceledRLS).format('$0,0.00') }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 text-center">
					<div class="card bg-success border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Efectivo</div>
								<div class="font-size-18">
									{{ numeral(bankTerminalOperations[modalType]?.paymentMethods.cash).format('$0,0.00') }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 text-center">
					<div class="card bg-info border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Digital</div>
								<div class="font-size-18">
									{{ numeral(totalDigital).format('$0,0.00') }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-center">
					<div class="card bg-info border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Terminal Bancaria</div>
								<div class="font-size-18">
									{{ numeral(bankTerminalOperations[modalType]?.credit + bankTerminalOperations[modalType]?.credit_msi + bankTerminalOperations[modalType]?.debit).format('$0,0.00') }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-center">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Terminal Crédito</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations[modalType]?.credit).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-center">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Terminal Crédito 3 MSI</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations[modalType]?.credit_msi).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-center">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Terminal Débito</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations[modalType]?.debit).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center" :class="modalType == 'pacific' ? 'col-md-3' : 'col-md-4'">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Transferencia</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations[modalType]?.paymentMethods?.transfer || 0).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center" :class="modalType == 'pacific' ? 'col-md-3' : 'col-md-4'">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">PayPal</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations[modalType]?.paymentMethods?.paypal || 0).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center" :class="modalType == 'pacific' ? 'col-md-3' : 'col-md-4'">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">MercadoPago</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations[modalType]?.paymentMethods?.mercadopago).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-center" v-if="modalType == 'pacific'">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Aplazo</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations[modalType]?.paymentMethods?.aplazo.total).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import numeral from 'numeral'
import ticketComponent from '@/components/ticket'
import _ from 'lodash'
import utilities from '@/services/utilities'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'posDashboardView',
	components: {
		ticketComponent,
	},
	directives: {
		mask,
	},
	computed: {
		...mapGetters('pos', ['filters']),
		...mapGetters('users', ['usersList']),
		...mapGetters('user', ['user']),
		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
		spinnerLoaderLabel() {
			return this.$store.state.pos.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.pos.spinnerLoader
		},
		totalDigital() {
			if (utilities.objectValidate(this.bankTerminalOperations, [this.modalType, 'paymentMethods'], false)) {
				return (
					numeral(utilities.objectValidate(this.bankTerminalOperations, [this.modalType, 'credit']), 0).value() +
					numeral(utilities.objectValidate(this.bankTerminalOperations, [this.modalType, 'credit_msi']), 0).value() +
					numeral(utilities.objectValidate(this.bankTerminalOperations, [this.modalType, 'debit']), 0).value() +
					numeral(utilities.objectValidate(this.bankTerminalOperations, [this.modalType, 'paymentMethods', 'mercadopago']), 0).value() +
					numeral(utilities.objectValidate(this.bankTerminalOperations, [this.modalType, 'paymentMethods', 'paypal']), 0).value() +
					numeral(utilities.objectValidate(this.bankTerminalOperations, [this.modalType, 'paymentMethods', 'transfer']), 0).value() +
					numeral(utilities.objectValidate(this.bankTerminalOperations, [this.modalType, 'paymentMethods', 'aplazo']), 0).value()
				)
			}
			return 0
		},
		totalCanceledPacific() {
			return (
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['pacific', 'canceled', 'credit']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['pacific', 'canceled', 'credit_msi']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['pacific', 'canceled', 'debit']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['pacific', 'canceled', 'paymentMethods', 'cash']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['pacific', 'canceled', 'paymentMethods', 'mercadopago']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['pacific', 'canceled', 'paymentMethods', 'paypal']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['pacific', 'canceled', 'paymentMethods', 'transfer']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['pacific', 'canceled', 'paymentMethods', 'aplazo']), 0).value()
			)
		},
		totalCanceledRLS() {
			return (
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['rls', 'canceled', 'credit']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['rls', 'canceled', 'credit_msi']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['rls', 'canceled', 'debit']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['rls', 'canceled', 'paymentMethods', 'cash']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['rls', 'canceled', 'paymentMethods', 'mercadopago']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['rls', 'canceled', 'paymentMethods', 'paypal']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['rls', 'canceled', 'paymentMethods', 'transfer']), 0).value() +
				numeral(utilities.objectValidate(this.bankTerminalOperations, ['rls', 'canceled', 'paymentMethods', 'aplazo']), 0).value()
			)
		},
		totalCanceledPanel() {
			// console.log('-->', this.bankTerminalOperations.panel)
			return numeral(utilities.objectValidate(this.bankTerminalOperations, ['panel', 'canceled', 'paymentMethods', 'cash']), 0).value()
		},
	},
	data() {
		return {
			filterType: 'day',
			filterBranchOffice: '',
			modalDetailDigitalVisible: false,
			modalDetailBoutiqueVisible: false,
			dateFormat,
			columns: [
				{
					title: 'Ticket/Cajero',
					scopedSlots: { customRender: 'id' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Carrito',
					scopedSlots: { customRender: 'cart' },
					align: 'center',
					width: '50%',
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Fecha',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Info ticket',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			],
			ticket: {},
			ticketVisible: false,
			giftTicket: false,
			localClient: {},
			tickets: [],
			bankTerminalOperations: {},
			totals: {
				panel: 0,
				pacific: {
					spareparts: 0,
					boutique: 0,
				},
				rls: 0,
				online: 0,
			},
			modalType: 'pacific',
		}
	},
	mounted() {
		this.getData()
		if (this.usersList.length == 0) {
			this.$store.dispatch('users/GET')
		}
	},
	methods: {
		moment,
		numeral,
		onResetFilter(key) {
			this.$store.dispatch('pos/RESET_ONE_FILTER', key)
			this.initModule()
		},
		initModule(date, dateString) {
			//
			let payload = {}

			if (this.filterType == 'range') {
				if (!!dateString && dateString.length) {
					payload = {
						startDate: moment(dateString[0]).format('YYYY-MM-DD'),
						endDate: moment(dateString[1]).format('YYYY-MM-DD'),
					}
				} else {
					payload = {
						startDate: moment(this.filters.defaultDate[0]).format('YYYY-MM-DD'),
						endDate: moment(this.filters.defaultDate[1]).format('YYYY-MM-DD'),
					}
				}
			} else {
				payload = {
					startDate: moment(this.filters.defaultDate[0]).format('YYYY-MM-DD'),
					endDate: moment(this.filters.defaultDate[0]).format('YYYY-MM-DD'),
				}
			}

			if (!!utilities.objectValidate(this, 'filterBranchOffice', false)) {
				payload.branch_office_id = this.filterBranchOffice
			}

			payload = {
				...payload,
				cashier: this.filters.cashier,
				paymentMethod: this.filters.paymentMethod,
			}

			this.tickets = []

			// console.log('payload-->', payload)
			this.$store.dispatch('pos/GET_TICKETS', payload).then((response) => {
				// console.log('GET_TICKETS-->', response)
				let totals = {
					panel: 0,
					pacific: {
						spareparts: 0,
						boutique: 0,
					},
					rls: 0,
					online: 0,
				}

				// ordenamos por fecha la lista de tickets
				let tickets = _.orderBy(response.tickets, ['created_at'], ['desc'])

				tickets.forEach((element) => {
					// console.log('element-->', element);
					if (element.from_panel) {
						totals.panel += numeral(element.total).value()
					}
					if (element.from_pacific) {
						//
						if (utilities.objectValidate(element, 'salesman_info', false)) {
							totals.online += numeral(element.total).value()
						} else {
							// separamos refacciones de boutique
							if (utilities.objectValidate(element, 'cart', []).length) {
								let { cart } = element
								for (let index = 0; index < cart.length; index++) {
									const cartElement = cart[index]
									switch (utilities.objectValidate(cartElement, 'group', '')) {
										case 2:
											// BOUTIQUE
											totals.pacific.boutique += cartElement.amount
											break
										case 3:
											// REFACCIONARIA
											totals.pacific.spareparts += cartElement.amount
											break
									}
								}
							}
						}
					}
					if (element.from_rls) {
						totals.rls += numeral(element.total).value()
					}
				})

				this.tickets = _.cloneDeep(tickets)
				this.bankTerminalOperations = _.cloneDeep(response.bankTerminalOperations)
				this.totals = _.cloneDeep(totals)
			})
		},
		getData() {
			this.initModule()
		},
		onPrintTicket(record, giftTicket = false) {
			this.giftTicket = giftTicket

			this.ticket = {
				fullName: record.fullName,
				cart: utilities.objectValidate(record, 'cart', false) ? record.cart : [],
				ticketData: {
					id: record.id,
					created_at: record.created_at,
					validator: record.validator,
					branchOfficeId: record.branch_office_id,
				},
				paymentData: {
					payment_bank_terminal: record.payment_bank_terminal,
					payment_cash: record.payment_cash,
					payment_mercadopago: record.payment_mercadopago,
					payment_paypal: record.payment_paypal,
					payment_transfer: record.payment_transfer,
					payment_advance: record.payment_advance,
					payment_billpocket: record.payment_billpocket,
					payment_aplazo: record.payment_aplazo,
				},
				advances: typeof record.advances === 'string' ? JSON.parse(record.advances) : _.cloneDeep(record.advances) || [],
				serviceOrder: numeral(utilities.objectValidate(record, 'service_order', 0)).value(),
				orderDiscount: numeral(utilities.objectValidate(record, 'order_discount', 0)).value(),
			}

			if (utilities.objectValidate(record, 'client', false)) {
				this.localClient = {
					id: record.client,
					client_last_surname: utilities.objectValidate(record, 'client_last_surname', ''),
					client_name: record.client_name,
					client_surname: record.client_surname,
					email: utilities.objectValidate(record, 'email', ''),
					primary_phone: utilities.objectValidate(record, 'primary_phone', ''),
				}
			} else {
				this.localClient = {}
			}

			this.ticketVisible = true

			setTimeout(() => {
				this.$htmlToPaper('reprintPosTicket')
			}, 500)

			setTimeout(() => {
				this.ticketVisible = false
			}, 3000)
		},
		onBillSale(id) {
			this.$store.dispatch('pos/GET_ONE_TICKET', id)
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		downloadBill(id) {
			this.$store.dispatch('pos/DOWNLOAD_INVOICE', id)
		},
		searchByTicket() {
			let payload = {
				ticket: this.filters.ticket,
			}

			this.$store.dispatch('pos/GET_TICKETS', payload)
		},
		onResetTicket(e) {
			if (e.target.value == '') {
				this.initModule()
			}
		},
		openModal(modalType) {
			this.modalDetailBoutiqueVisible = true
			this.modalType = modalType
		},
		onCloseModal(modal) {
			switch (modal) {
				case 'digital':
					this.modalDetailDigitalVisible = false
					break
				case 'boutique':
					this.modalDetailBoutiqueVisible = false
					break
			}
		},
		getAdvanceLabel(payload) {
			return JSON.parse(payload)[0].label
		},
		closeAdvance(ticket) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas asociar el anticipo a una venta?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					await Swal.fire({
						title: 'Ingresa # ticket de Pacific',
						input: 'text',
						inputValue: '',
						reverseButtons: true,
						showCancelButton: true,
						confirmButtonColor: '#41b883',
						confirmButtonText: 'Continuar',
						cancelButtonColor: '#f5222e',
						cancelButtonText: 'Cancelar',
						inputValidator: (value) => {
							if (!value) {
								return 'Es necesario ingresar # ticket de Pacific'
							}
						},
					}).then((resConfirm) => {
						if (resConfirm.isConfirmed) {
							//
							this.$store.dispatch('pos/CLOSE_ADVANCE', {
								ticket,
								pacific: resConfirm.value,
							})
						}
					})
				}
			})
		},
	},
}
</script>
